/*!------------------------------------------------------------------
[MAIN STYLESHEET]

PROJECT:	Project Name
VERSION:	Versoin Number
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[TABLE OF CONTENTS]
-------------------------------------------------------------------*/
/*  typography */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700");
@font-face {
  font-family: 'futura-bold';
  src: url("../fonts/Futura-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  line-height: 1.2;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  color: #5c5c77;
}

p, .paragraph {
  font-weight: 400;
  color: #5c5c77;
  font-size: 15px;
  line-height: 1.9;
  font-family: "Roboto", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: #1e1e4b;
  font-family: "futura-bold";
  font-weight: 700;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 60px;
}

@media (max-width: 575px) {
  h1, .h1 {
    font-size: 45px;
  }
}

h2, .h2 {
  font-size: 40px;
}

@media (max-width: 575px) {
  h2, .h2 {
    font-size: 30px;
  }
}

h3, .h3 {
  font-size: 25px;
}

@media (max-width: 575px) {
  h3, .h3 {
    font-size: 20px;
  }
}

h4, .h4 {
  font-size: 20px;
}

@media (max-width: 575px) {
  h4, .h4 {
    font-size: 18px;
  }
}

h5, .h5 {
  font-size: 18px;
}

@media (max-width: 575px) {
  h5, .h5 {
    font-size: 16px;
  }
}

h6, .h6 {
  font-size: 16px;
}

@media (max-width: 575px) {
  h6, .h6 {
    font-size: 14px;
  }
}

/* Button style */
.btn {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  padding: 15px 40px;
  border-radius: 0;
  font-weight: 500;
  border: 0;
  position: relative;
  z-index: 1;
  transition: .2s ease;
  overflow: hidden;
}

.btn::before {
  position: absolute;
  content: "";
  height: 80%;
  width: 100%;
  left: 0;
  bottom: 10%;
  z-index: -1;
  transition: transform .2s ease-in-out;
  transform-origin: top;
  transform: scaleY(0);
}

.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.btn:active {
  box-shadow: none;
}

.btn:hover::before {
  transform: scaleY(1);
  transform-origin: bottom;
}

.btn-sm {
  font-size: 14px;
  padding: 10px 35px;
}

.btn-xs {
  font-size: 12px;
  padding: 5px 15px;
}

.btn-primary {
  background: #01a6f4;
  color: #fff;
}

.btn-primary::before {
  background: #fff;
}

.btn-primary:active {
  background: #01a6f4 !important;
  color: #01a6f4;
}

.btn-primary:active::before {
  height: 80%;
}

.btn-primary:hover {
  background: #ffab08;
  color: #01a6f4;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ffab08;
  border-color: #ffab08;
}

.btn-secondary {
  background: #fff;
  color: #01a6f4;
  border: 1px solid #fff;
}

.btn-secondary::before {
  background: #01a6f4;
}

.btn-secondary:active {
  background: #01a6f4;
  color: #fff;
  border: 1px solid #fff;
}

.btn-secondary:hover {
  background: #fff;
  color: #fff;
  border: 1px solid #fff;
}

.btn-primary-outline {
  border: 1px solid #01a6f4;
  color: #01a6f4;
  background: transparent;
}

.btn-primary-outline::before {
  background: #fff;
}

.btn-primary-outline:hover {
  background: #01a6f4;
  color: #01a6f4;
}

.btn-primary-outline:active {
  background: #01a6f4;
  color: #fff;
}

body {
  background-color: #fafafa;
  overflow-x: hidden;
}

::selection {
  background: #ffcd6e;
  color: #fff;
}

/* preloader */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFDC11;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

ol,
ul {
  list-style-type: none;
  margin: 0px;
}

img {
  vertical-align: middle;
  border: 0;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a,
button,
select {
  cursor: pointer;
  transition: .2s ease;
}

a:focus,
button:focus,
select:focus {
  outline: 0;
}

a:hover {
  color: #01a6f4;
}

a.text-primary:hover {
  color: #01a6f4 !important;
}

a.text-light:hover {
  color: #01a6f4 !important;
}

h4 {
  transition: .2s ease;
}

a h4:hover {
  color: #01a6f4;
}

.slick-slide {
  outline: 0;
}

.section {
  padding-top: 90px;
  padding-bottom: 90px;
}

.section-sm {
  padding-top: 40px;
  padding-bottom: 40px;
}

.section-title {
  margin-bottom: 30px;
}

.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.border-primary {
  border-color: #ededf1 !important;
}

/* overlay */
.overlay {
  position: relative;
}

.overlay::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #1a1a37;
  opacity: .8;
}

.outline-0 {
  outline: 0 !important;
}

.d-unset {
  display: unset !important;
}

.bg-primary {
  background: #01a6f4 !important;
}

.bg-secondary {
  background: #1a1a37 !important;
}

.bg-gray {
  background: #f8f8f8;
}

.text-primary {
  color: #01a6f4 !important;
}

.text-color {
  color: #5c5c77;
}

.text-light {
  color: #8585a4 !important;
}

.text-lighten {
  color: #d6d6e0 !important;
}

.text-muted {
  color: #b5b5b7 !important;
}

.text-dark {
  color: #1e1e4b !important;
}

.font-secondary {
  font-family: "futura-bold";
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.pl-150 {
  padding-left: 150px;
}

.zindex-1 {
  z-index: 1;
}

@media (max-width: 991px) {
  .overflow-md-hidden {
    overflow: hidden;
  }
}

.vertical-align-middle {
  vertical-align: middle;
}

.icon-md {
  font-size: 36px;
}

/* page title */
.page-title-section {
  padding: 200px 0 80px;
}

.custom-breadcrumb li.nasted {
  position: relative;
  padding-left: 25px;
}

.custom-breadcrumb li.nasted::before {
  position: absolute;
  font-family: "themify";
  content: "\e649";
  font-size: 20px;
  top: 50%;
  left: -5px;
  color: #fff;
  transform: translateY(-50%);
}

/* /page title */
.list-styled {
  padding-left: 25px;
}

.list-styled li {
  position: relative;
  margin-bottom: 15px;
}

.list-styled li::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #01a6f4;
  left: -25px;
  top: 5px;
}

textarea.form-control {
  height: 200px;
  padding: 20px;
}

#map_canvas {
  height: 500px;
}

.top-header {
  font-size: 12px;
  transition: transform .2s ease;
  transform-origin: top;
  font-weight: 600;
}

.top-header.hide {
  transform: scaleY(0);
  transform-origin: top;
}

.navigation {
  background: linear-gradient(to right, transparent 50%, #01a6f4 50%);
  transition: .2s ease;
}

@media (max-width: 991px) {
  .navigation {
    background: #1a1a37;
  }
}

@media (max-width: 991px) {
  .navbar-collapse {
    background: #1a1a37;
  }
}

.navbar-nav {
  padding-left: 50px;
  background: #01a6f4;
}

@media (max-width: 991px) {
  .navbar-nav {
    padding-left: 0;
    background: #1a1a37;
  }
}

.nav-bg {
  background-color: #1a1a37;
}

.nav-item {
  margin: 0 15px;
  position: relative;
}

.nav-item .nav-link {
  text-transform: uppercase;
  font-weight: 600;
}

.nav-item::before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 6px;
  width: 100%;
  content: "";
  background: #fff;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform .3s ease;
}

.nav-item:hover::before, .nav-item.active::before {
  transform: scaleY(1);
  transform-origin: bottom;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 40px 0px;
}

@media (max-width: 991px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 20px;
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background: #fff;
  box-shadow: 0 2px 5px #0000000d;
}

.navbar .dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.navbar .dropdown-menu {
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.12);
  border-bottom: 5px solid #01a6f4;
  padding: 15px;
  top: 96px;
  border-radius: 0;
  display: block;
  visibility: hidden;
  transition: .3s ease;
  opacity: 0;
  transform: translateY(20px);
  background: #fff;
}

@media (max-width: 991px) {
  .navbar .dropdown-menu {
    display: none;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transform-origin: unset;
  }
}

.navbar .dropdown-menu.show {
  visibility: hidden;
}

@media (max-width: 991px) {
  .navbar .dropdown-menu.show {
    visibility: visible;
    display: block;
  }
}

.navbar .dropdown-item {
  position: relative;
  color: #1e1e4b;
  transition: .2s ease;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 991px) {
  .navbar .dropdown-item {
    text-align: center;
  }
}

.navbar .dropdown-item:not(:last-child) {
  margin-bottom: 10px;
}

.navbar .dropdown-item:hover {
  color: #01a6f4;
  background: transparent;
}

.hero-section {
  padding: 250px 0 290px;
}

.hero-slider .prevArrow,
.hero-slider .nextArrow {
  position: absolute;
  bottom: -123px;
  z-index: 9;
  padding: 15px;
  color: rgba(255, 255, 255, 0.5);
  border: 0;
  font-size: 30px;
  transition: all linear .2s;
  background: transparent;
}

.hero-slider .prevArrow:focus,
.hero-slider .nextArrow:focus {
  outline: 0;
}

.hero-slider .prevArrow:hover,
.hero-slider .nextArrow:hover {
  color: #01a6f4;
}

.hero-slider .prevArrow {
  right: 60px;
}

.hero-slider .nextArrow {
  right: 0;
}

.hero-slider .slick-dots {
  position: absolute;
  left: 0;
  bottom: -100px;
  padding-left: 0;
}

.hero-slider .slick-dots li {
  display: inline-block;
  margin: 0 6px;
}

.hero-slider .slick-dots li.slick-active button {
  background: #01a6f4;
}

.hero-slider .slick-dots li button {
  color: transparent;
  padding: 0;
  overflow: hidden;
  height: 10px;
  width: 10px;
  background: rgba(255, 255, 255, 0.5);
  border: 0;
  outline: 0;
}

/* banner feature */
.feature-icon {
  font-size: 50px;
  color: #01a6f4;
  display: inline-block;
}

.feature-blocks {
  margin-top: -100px;
  padding-left: 70px;
  padding-top: 80px;
  padding-right: 30%;
}

@media (max-width: 1400px) {
  .feature-blocks {
    padding-right: 10%;
  }
}

@media (max-width: 1200px) {
  .feature-blocks {
    padding-right: 50px;
    padding-left: 50px;
    padding-top: 30px;
  }
  .feature-blocks h3 {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .feature-blocks {
    margin-top: 0;
    padding: 50px;
  }
  .feature-blocks h3 {
    font-size: 25px;
  }
}

/* /banner feature */
/* course */
.card-btn {
  font-size: 12px;
  padding: 5px 10px;
}

.flex-basis-33 {
  flex-basis: 33.3333%;
}

.hover-shadow {
  transition: .3s ease;
}

.hover-shadow:hover {
  box-shadow: 0px 4px 25px 0px rgba(27, 39, 71, 0.15);
}

/* /course */
/* success story */
.success-video {
  min-height: 300px;
}

.success-video .play-btn {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .success-video .play-btn {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.play-btn {
  display: inline-block;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: #01a6f4;
  color: #fff;
  font-size: 20px;
  text-align: center;
}

.play-btn i {
  line-height: 80px;
}

.play-btn::before {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  z-index: -2;
  transition: .3s ease;
  transition-delay: .2s;
}

.play-btn::after {
  position: absolute;
  content: "";
  height: 80%;
  width: 80%;
  transform: translate(-50%, -50%);
  background: #01a6f4;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: .3s ease;
}

.play-btn:hover::before {
  height: 80%;
  width: 80%;
  transition-delay: 0s;
}

.play-btn:hover::after {
  height: 0;
  width: 0;
  transition: 0s ease;
}

/* /success story */
/* events */
.card-date {
  position: absolute;
  background: #01a6f4;
  font-family: "futura-bold";
  text-align: center;
  padding: 10px;
  color: #fff;
  top: 0;
  left: 0;
  text-transform: uppercase;
}

.card-date span {
  font-size: 40px;
}

/* /events */
/* teacher */
.teacher-info {
  width: 70%;
  bottom: 0;
  right: 0;
}

/* /teacher */
/* footer */
.newsletter {
  background-image: linear-gradient(to right, transparent 50%, #01a6f4 50%);
  margin-bottom: -170px;
  position: relative;
  z-index: 1;
}

.newsletter-block {
  padding-left: 50px;
}

@media (max-width: 575px) {
  .newsletter-block {
    padding-left: 15px;
  }
}

.input-wrapper {
  position: relative;
}

.input-wrapper button {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.form-control {
  height: 60px;
  background: #fff;
  border-radius: 0;
  padding-left: 25px;
}

.form-control:focus {
  border-color: #01a6f4;
  box-shadow: none;
}

.newsletter-block .form-control {
  height: 90px;
}

.bg-footer {
  background-color: #182b45;
}

.logo-footer {
  margin-top: -20px;
  display: inline-block;
}

.footer {
  border-color: #494a43 !important;
  padding-top: 275px;
}

/* /footer */
.filter-controls li {
  cursor: pointer;
  transition: .1s ease;
}

.filter-controls li.active {
  font-weight: 600;
  color: #01a6f4;
}

.filter-controls li:hover {
  color: #01a6f4;
}

/*  typography */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700');

@font-face {
  font-family: 'futura-bold';
  src: url('../fonts/Futura-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  line-height: 1.2;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  color: $text-color;
}

p, .paragraph {
  font-weight: 400;
  color: $text-color;
  font-size: 15px;
  line-height: 1.9;
  font-family: $primary-font;
}

h1,h2,h3,h4,h5,h6 {
  color: $text-color-dark;
  font-family: $secondary-font;
  font-weight: 700;
  line-height: 1.2;
}

h1, .h1{
  font-size: 60px;
  @include mobile {
    font-size: 45px;
  }
}

h2, .h2{
  font-size: 40px;
  @include mobile {
    font-size: 30px;
  }
}

h3, .h3{
  font-size: 25px;
  @include mobile {
    font-size: 20px;
  }
}

h4, .h4{
  font-size: 20px;
  @include mobile {
    font-size: 18px;
  }
}

h5, .h5{
  font-size: 18px;
  @include mobile {
    font-size: 16px;
  }
}

h6, .h6{
  font-size: 16px;
  @include mobile {
    font-size: 14px;
  }
}

body {
  background-color: $body-color;
  overflow-x: hidden;
}

::selection {
  background: lighten($color: $primary-color, $amount: 10);
  color: $white;
}

/* preloader */

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFDC11;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

ol,
ul {
  list-style-type: none;
  margin: 0px;
}

img {
  vertical-align: middle;
  border: 0;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a,
button,
select {
  cursor: pointer;
  transition: .2s ease;

  &:focus {
    outline: 0;
  }
}

a:hover {
  color: $primary-color;
}

a.text-primary:hover {
  color: $primary-color!important;
}

a.text-light:hover {
  color: $primary-color!important;
}

h4{
  transition: .2s ease;
}
a h4{
  &:hover{
    color: $primary-color;
  }
}

.slick-slide {
  outline: 0;
}

.section {
  padding-top: 90px;
  padding-bottom: 90px;

  &-sm {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &-title {
    margin-bottom: 30px;
  }
}

.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.border-primary {
  border-color: $border-color !important;
}

/* overlay */

.overlay {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: $secondary-color;
    opacity: .8;
  }
}

.outline-0 {
  outline: 0 !important;
}

.d-unset {
  display: unset !important;
}

.bg-primary {
  background: $primary-color !important;
}

.bg-secondary {
  background: $secondary-color !important;
}

.bg-gray {
  background: $gray;
}

.text-primary {
  color: $primary-color !important;
}

.text-color {
  color: $text-color;
}

.text-light {
  color: $text-color-light !important;
}

.text-lighten{
  color: #d6d6e0 !important;
}

.text-muted {
  color: #b5b5b7 !important;
}

.text-dark {
  color: $text-color-dark !important;
}

.font-secondary {
  font-family: $secondary-font;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.pl-150 {
  padding-left: 150px;
}

.zindex-1 {
  z-index: 1;
}

.overflow-md-hidden {
  @include desktop {
    overflow: hidden;
  }
}

.vertical-align-middle{
  vertical-align: middle;
}

.icon-md{
  font-size: 36px;
}

/* page title */
.page-title-section {
  padding: 200px 0 80px;
}

.custom-breadcrumb {
  li {
    &.nasted {
      position: relative;
      padding-left: 25px;

      &::before {
        position: absolute;
        font-family: $icon-font;
        content: "\e649";
        font-size: 20px;
        top: 50%;
        left: -5px;
        color: $white;
        transform: translateY(-50%);
      }
    }
  }
}

/* /page title */

.list-styled{
  padding-left: 25px;
  li{
    position: relative;
    margin-bottom: 15px;
    &::before{
      position: absolute;
      content: "";
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: $primary-color;
      left: -25px;
      top: 5px;
    }
  }
}

textarea.form-control{
  height: 200px;
  padding: 20px;
}

#map_canvas{
  height: 500px;
}